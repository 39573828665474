import { createStyles } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { ContactOrganization } from "../components/ContactOrganization";
import ItemsTable from "../components/ItemsTable";
import ModalAcceptTerms from "../components/ModalAcceptTerms";
import OrderInfo from "../components/OrderInfo";
import ProgressRing from "../components/ProgressRing";
import RatingAndComments from "../components/RatingAndComments";
import Search from "../components/Search";
import useUpdateOrder from "../hooks/useUpdateOrder";

const styles = createStyles((theme) => ({
  container: {
    // backgroundImage: theme.fn.gradient({
    //   from: "blue.9",
    //   to: "teal.9",
    //   deg: 140,
    // }),
    backgroundColor: theme.fn.themeColor("blue.9"),
  },
}));
export interface IItem {
  id: string;
  name: string;
  quantity?: number;
  pricePerUnit: number;
  type: string;
  length?: number;
  width?: number;
  totalPriceItem?: number;
}
export interface IOrder {
  id: string;
  organizationPhone: string;
  generatedTicketId: string;
  organizationLanguage?: string;
  organizationTimezone?: string;
  organizationCurrency?: string;
  countryPrefix?: string;
  status: string;
  name: string;
  phone: string;
  pickupDate: Date;
  deliveryDate?: Date;
  aproximateDelivery?: Date;
  feedbackSubmitted?: boolean;
  preferredDeliveryDate?: boolean;
  initialProductsNumber?: number;
  aproximateHourDelivery?: string;
  organizationLogo?: string;
  organizationName?: string;
  deliveryDateChanged?: {
    date: Date;
    reason: string;
    time: string;
  };
  feedback?: {
    rating: number;
    comment: string;
  };
  items?: IItem[];
  clientAgreedTerms?: boolean;
  organizationTermsAndConditions?: string;
  totalPrice?: number;
  restToMinimumOrder?: number;
  minimumOrder?: number;
  abilityToAddPreferredDeliveryDate?: boolean;
}

const InProgress: React.FC = () => {
  const { classes } = styles();
  // param id is a path parameter, e.g. /verifica/123
  const isMobile = useMediaQuery("(max-width: 60em)");
  const { mutate, isSuccess } = useUpdateOrder();
  const [reloadOrder, setReloadOrder] = useState(false);
  const [opened, { close, open }] = useDisclosure(false);

  const [preferedDateSubmmited, setPrefferedDateSubmitted] =
    useState<number>(0);
  const [order, setOrder] = useState<IOrder | null>(); // Adjust the type of order as per your API response
  const giveFeedback = (orderId: string, rating: number, comment: string) => {
    // Send feedback to API
    mutate({
      id: orderId,
      feedback: {
        rating,
        comment,
      },
      feedbackSubmitted: true,
    });
  };
  useEffect(() => {
    if (isSuccess && preferedDateSubmmited) {
      setReloadOrder((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, preferedDateSubmmited]);

  const updateDeliveryDate = (
    orderId: string,
    date: Date,
    reason: string,
    time: string
  ) => {
    // Send feedback to API
    setPrefferedDateSubmitted((prev) => prev + 1);

    mutate({
      id: orderId,
      deliveryDateChanged: {
        date,
        reason,
        time,
      },
      preferredDeliveryDate: true,
    });
  };

  useEffect(
    () => {
      if (order?.clientAgreedTerms === false) {
        open();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order?.clientAgreedTerms]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
          minHeight: "100vh",
        }}
        className={classes.container}
      >
        <ModalAcceptTerms
          terms={order?.organizationTermsAndConditions || ""}
          opened={opened}
          onClose={() => {
            close();
            setReloadOrder((prev) => !prev);
          }}
          orderId={order?.generatedTicketId || ""}
        />

        <Search
          setOrder={setOrder}
          isMobile={isMobile}
          order={order}
          reloadOrder={reloadOrder}
        />

        {order && (
          <div
            style={{
              borderRadius: "4px",
              margin: "20px",
              width: isMobile ? "100%" : "50%",
              maxWidth: isMobile ? "100%" : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              border: "3px solid #fff",
              fontFamily: "DM Mono, sans-serif",
              color: "#fff",
            }}
          >
            <ProgressRing status={order.status} />
            <ContactOrganization order={order} />
            <OrderInfo order={order} updateDeliveryDate={updateDeliveryDate} />

            {order.status === "delivered" && !order.feedbackSubmitted && (
              <RatingAndComments
                orderId={order.generatedTicketId}
                giveFeedback={giveFeedback}
              />
            )}
            {order.status !== "pickedUp" && (
              <ItemsTable
                items={order.items}
                isMobile={isMobile}
                organizationCurrency={order?.organizationCurrency}
                totalPrice={order?.totalPrice}
                minimumOrder={order?.minimumOrder}
                restToMinimumOrder={order?.restToMinimumOrder}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default InProgress;
